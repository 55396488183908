export const ALL_TAB_NAME = 'All';

export const toggleServiceSelection = (serviceId, serviceIds) => {
  return serviceIds.includes(serviceId)
    ? serviceIds.filter((id) => id !== serviceId)
    : [...serviceIds, serviceId];
};

export const generateTabs = (priceLists) => {
  return [ALL_TAB_NAME, ...priceLists.map((pl) => pl.name)];
};

const filterServicesByPriceList = (priceListId, services) => {
  if (priceListId === ALL_TAB_NAME) {
    return services;
  }

  return services.filter((service) => service.priceListId === priceListId);
};

export const getServicesByTab = (tab, priceLists, services) => {
  if (tab === ALL_TAB_NAME) {
    return services;
  }

  const priceList = priceLists.find((pl) => pl.name === tab);

  return filterServicesByPriceList(priceList.id, services);
};

export const getSelectedServicesTotal = (selectedIds = [], services = []) => {
  if (!selectedIds?.length || !services?.length) {
    return 0;
  }

  const selectedIdsSet = new Set(selectedIds);

  return services.reduce((total, service) => (
    service.showPricing && selectedIdsSet.has(service.id)
      ? total + (service.price ?? 0)
      : total
  ), 0);
};
