import React from 'react';
import { useQuery } from 'react-query';
import PropTypes from 'prop-types';

import { getPublicServicesAndLists } from 'api/index';

import TabContainer from 'components/tab-container';
import {
  Stack,
  Group,
  Typography,
  Money,
} from 'components/ui-kit';

import { getPluralForm } from 'helpers/text.helper';

import ServiceCard from './components/service-card';

import {
  ALL_TAB_NAME,
  toggleServiceSelection,
  generateTabs,
  getServicesByTab,
  getSelectedServicesTotal,
} from './utils';

import * as S from './styles';

const ServicesStep = ({
  values,
  setFieldValue,
  isPreview,
}) => {
  const servicesCount = values?.serviceIds?.length;

  const { data, isError, isLoading } = useQuery(
    ['publicServices', values?.portalId],
    () => getPublicServicesAndLists({ queryKey: ['publicServices', values.portalId] }),
    {
      enabled: isPreview && !!values?.portalId,
    },
  );

  if (isError) {
    return <Typography>Error loading services</Typography>;
  }

  if (isLoading) {
    return <Typography>Loading services...</Typography>;
  }

  const { data: { services = [], priceLists = [] } = {} } = data || {};

  const servicesTotalAmount = getSelectedServicesTotal(values?.serviceIds, services);

  const tabs = generateTabs(priceLists);

  const handleServiceSelect = (serviceId) => {
    const updatedServiceIds = toggleServiceSelection(serviceId, values.serviceIds);
    setFieldValue('serviceIds', updatedServiceIds);
  };

  return (
    <Stack gap={16}>
      <S.Header>
        Book Online
      </S.Header>

      <Typography font="regular" fontSize="16px" fontWeight={600}>Select services</Typography>

      <TabContainer
        tabs={tabs}
        h={32}
        noGap
        withoutBorder
        withoutPadding
        borderedItems
      >
        {tabs.map((tab) => (
          <Stack w="100%" gap={16} key={tab}>
            <Typography fontFamily="Poppins" fontSize={16} fontWeight={600} color="dark">
              {tab === ALL_TAB_NAME ? 'All Services' : tab}
            </Typography>

            <Stack w="100%" gap={8}>
              {getServicesByTab(tab, priceLists, services).map((service) => (
                <ServiceCard
                  key={service.id}
                  name={service.name}
                  description={service.description}
                  showPricing={service.showPricing}
                  price={service.price}
                  imageUrl={service.imageUrl}
                  isSelected={values.serviceIds.includes(service.id)}
                  onClick={() => handleServiceSelect(service.id)}
                />
              ))}
            </Stack>
          </Stack>
        ))}
      </TabContainer>

      {!!servicesCount && (
        <S.TotalWrapper>
          <Typography font="regular" fontSize={14} fontWeight={600} color="neutral500">
            {`${servicesCount} ${getPluralForm(servicesCount, 'service')} selected`}
          </Typography>

          {!!servicesTotalAmount && (
            <Group>
              <Typography font="regular" fontSize={14} fontWeight={600} color="neutral500">
                Total:&nbsp;
              </Typography>

              <Typography font="regular" fontSize={14} fontWeight={600} color="dark">
                <Money amount={servicesTotalAmount} />
              </Typography>
            </Group>
          )}
        </S.TotalWrapper>
      )}
    </Stack>
  );
};

ServicesStep.defaultProps = {
  isPreview: false,
};

ServicesStep.propTypes = {
  form: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.shape({
    serviceIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    portalId: PropTypes.string.isRequired,
  }).isRequired,
  isPreview: PropTypes.bool,
};

export default ServicesStep;
