import styled from 'styled-components';

import { Stack } from 'components/ui-kit';

import { defaultColor } from 'styles/theme';

export const Header = styled.div`
  color: ${defaultColor('dark')};
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
`;

export const TotalWrapper = styled(Stack)`
  align-self: flex-end;
  gap: 10px;
  align-items: flex-end; 
`;
