import React from 'react';
import PropTypes from 'prop-types';
import useCurrency from 'hooks/useCurrency';

function Money({
  amount,
  fallback,
}) {
  const { formatCurrency } = useCurrency();

  if (typeof amount === 'string') {
    return amount || fallback;
  }

  if (typeof amount !== 'number') {
    return fallback;
  }

  return (
    <>
      {formatCurrency(amount)}
    </>
  );
}

Money.propTypes = {
  amount: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  fallback: PropTypes.string,
};

Money.defaultProps = {
  amount: '',
  fallback: '',
};

export default Money;
